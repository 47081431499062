import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import CategoryModel from './CategoryModel';
import './Home.scss';
import { useUser } from '../../App';
import { DomainUrl } from '../../config';

const Home = () => {
  const [homePosts, setHomePosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const currentUserId = useUser();
  const postsPerPage = 9; // Adjusted to match backend limit

  // Use useRef to store cached posts
  const cachedPosts = useRef({});

  const fetchPosts = useCallback(async (pageNumber) => {
    if (!currentUserId) {
      console.warn("currentUserId is undefined, aborting fetch");
      return;
    }

    // Check if we have cached posts for this page
    if (cachedPosts.current[pageNumber]) {
      setHomePosts(cachedPosts.current[pageNumber]);
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const response = await axios.get(`${DomainUrl}/api/home/gethomeposts`, {
        params: {
          userId: currentUserId,
          page: pageNumber,
          limit: postsPerPage
        }
      });
  
      const newPosts = response.data.posts || [];
  
      // Send new posts to backend to set views
      if (newPosts.length > 0) {
        try {
          await axios.post(`${DomainUrl}/api/posts/postviews`, {
            postIds: newPosts.map(post => post.post_id)
          });
        } catch (viewErr) {
          console.error('Error setting views:', viewErr);
        }
      }
  
      // Cache the fetched posts
      cachedPosts.current[pageNumber] = newPosts;

      setHomePosts(newPosts);
      setTotalPages(response.data.totalPages || 1);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching posts:', err);
      setError('Failed to fetch posts');
      setLoading(false);
    }
  }, [currentUserId, postsPerPage]);
  
  useEffect(() => {
    fetchPosts(currentPage);
  }, [fetchPosts, currentPage, currentUserId]);
  
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const renderPagination = () => {
    const pageNumbers = [];
    let startPage, endPage;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`pagination-button ${currentPage === i ? 'active' : ''}`}
        >
          {i}
        </button>
      );
    }

    return (
      <div className="paginationn">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="pagination-button"
        >
          Previous
        </button>
        {startPage > 1 && <span className="pagination-ellipsis">...</span>}
        {pageNumbers}
        {endPage < totalPages && <span className="pagination-ellipsis">...</span>}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="pagination-button"
        >
          Next
        </button>
      </div>
    );
  };

  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className='CLSS'>
      {homePosts && homePosts.length > 0 ? (
        <>
          <CategoryModel posts={homePosts} />
          {loading && <div className="loading-message">Loading posts...</div>}
          {!loading && homePosts.length > 0 && renderPagination()}
        </>
      ) : (
        <p>No posts available.</p>
      )}
    </div>
  );
};

export default Home;

// Break Point