import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './DraftList.scss';
import { useUser } from '../../App';
import DraftListShow from './DraftListShow';
import { DomainUrl } from '../../config';

const DraftList = () => {
  const [draftPosts, setDraftPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const currentUserId = useUser();
  const postsPerPage = 10; // Adjust as needed

  useEffect(() => {
    fetchDraftPosts();
  }, [currentUserId, page]);

  const fetchDraftPosts = async () => {
    if (!currentUserId) {
      return;
    }

    try {
      setLoading(true);
      const response = await axios.get(`${DomainUrl}/api/drafts/getdraftposts`, {
        params: {
          userId: currentUserId,
          page: page,
          limit: postsPerPage
        }
      });

      const { drafts, totalPages: totalPagesFromServer, currentPage } = response.data;
      
      if (page === 1) {
        setDraftPosts(drafts);
      } else {
        setDraftPosts(prevPosts => [...prevPosts, ...drafts]);
      }

      setTotalPages(totalPagesFromServer);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch draft posts');
      setLoading(false);
    }
  };

  const loadMore = () => {
    if (!loading && page < totalPages) {
      setPage(prevPage => prevPage + 1);
    }
  };

  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className='CL'>
      <h1>Draft Posts</h1>
      {draftPosts && draftPosts.length > 0 ? (
        <>
          <DraftListShow posts={draftPosts} />
          {loading && <div className="loading-message">Loading more drafts...</div>}
          {!loading && page < totalPages && (
            <button onClick={loadMore} className="load-more-button">Load More</button>
          )}
          {!loading && page >= totalPages && draftPosts.length > 0 && (
            <div className="end-message">No more draft posts to load.</div>
          )}
        </>
      ) : (
        <p>No draft posts available.</p>
      )}
    </div>
  );
};

export default DraftList;