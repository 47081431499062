import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './PostList.scss';
import { useUser } from '../../App';
import CategoryListShow from '../categoryList/CategoryListShow';
import { DomainUrl } from '../../config';

const CategoryList = () => {
  const params = useParams();
  const username = params.id; // Convert username to a number
  const [savedPosts, setSavedPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const currentUserId = useUser();
  const postsPerPage = 10; // Adjust as needed

  
  useEffect(() => {
    fetchFilteredPosts();
  }, [username, page, currentUserId]);

  const fetchFilteredPosts = async () => {
    if (!username && !currentUserId) {
      console.warn("username is undefined, aborting fetch");
      return;
    }

    try {
      setLoading(true);
      const response = await axios.get(`${DomainUrl}/api/posts/getposts`, {
        params: {
          username: username,
          currentUserId: currentUserId,
          page: page,
          limit: postsPerPage
        }
      });

      // Update savedPosts based on new response structure
      const newPosts = response.data.posts || [];

      if (page === 1) {
        setSavedPosts(newPosts);
      } else {
        setSavedPosts(prevPosts => [...prevPosts, ...newPosts]);
      }

      setHasMore(newPosts.length === postsPerPage);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch posts');
      setLoading(false);
    }
  };

  const loadMore = () => {
    if (!loading && hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className='CL'>
      <h1>Posts</h1>
      {savedPosts && savedPosts.length > 0 ? (
        <>
          <CategoryListShow posts={savedPosts} />
          {loading && <div className="loading-message">Loading more posts...</div>}
          {!loading && hasMore && (
            <button onClick={loadMore} className="load-more-button">Load More</button>
          )}
          {!loading && !hasMore && savedPosts.length > 0 && (
            <div className="end-message">No more posts to load.</div>
          )}
        </>
      ) : (
        <p>No posts available.</p>
      )}
    </div>
  );
};

export default CategoryList;
