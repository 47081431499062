import React, { useState } from 'react';
import './PostModel.scss';
import { useUser } from '../../App';
import { formatFollowerCount } from '../../formatFollowerCount';
import { Link, useNavigate } from 'react-router-dom';
import { DomainUrl } from '../../config';
import Like from '../../icons/like.png';
import LikeActive from '../../icons/likeActive.png';
import Views from '../../icons/views.png';
import Share from '../../icons/share.png';
import Save from '../../icons/bookmark.png';
import SaveActive from '../../icons/bookmarkedD.png';
import moment from 'moment';
import ShareModal from '../../ShareModal';
const draftDefaultImage = '/DefaultImg/profileDefault.png';
const postDefaultImage = '/DefaultImg/postDefault.jpg';

const PostModel = ({
  postId,
  userId,
  userName = "Anonymous", // Default username
  userProfPic = "defaultProfilePic.jpg", // Default profile picture
  postImages = [], // Default to empty array for post images
  postTitle = "Untitled", // Default title
  postContent = "No content available.", // Default content
  likes = 0, // Default to 0 likes
  views = 0, // Default to 0 views
  time = "Just now", // Default time value
  isLiked = false, // Passed as a prop to manage like status
  isSaved = false, // Passed as a prop to manage save status
  category = "Common"
}) => {

  const currentUserId = useUser();
  const navigate = useNavigate();

  const [liked, setLiked] = useState(isLiked);  // Controlled by prop
  const [saved, setSaved] = useState(isSaved);  // Controlled by prop
  const [likeCount, setLikeCount] = useState(likes);

  const likeIcon = liked ? LikeActive : Like;
  const saveIcon = saved ? SaveActive : Save;

  const MAX_TITLE_LENGTH = 50;
  const MAX_CONTENT_LENGTH = 200;

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isPostMenuVisible, setIsPostMenuVisible] = useState(false);

  const truncatedTitle = postTitle.length > MAX_TITLE_LENGTH
    ? `${postTitle.substring(0, MAX_TITLE_LENGTH)}...`
    : postTitle;

  const truncatedContent = postContent.length > MAX_CONTENT_LENGTH
    ? `${postContent.substring(0, MAX_CONTENT_LENGTH)}...`
    : postContent;

  // Handle like action with immediate UI feedback
  const handleLikeClick = async () => {
    setLiked(!liked);
    setLikeCount(liked ? likeCount - 1 : likeCount + 1);

    try {
      const response = await fetch(`${DomainUrl}/api/likes/likepost`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          postId: postId,
          userId: currentUserId,
        }),
      });

      if (!response.ok) {
        // Rollback UI in case of an error
        setLiked(liked);
        setLikeCount(liked ? likeCount + 1 : likeCount - 1);
        console.error('Failed to like/unlike the post');
      }
    } catch (error) {
      // Rollback UI in case of an error
      setLiked(liked);
      setLikeCount(liked ? likeCount + 1 : likeCount - 1);
      console.error('Error liking/unliking the post:', error);
    }
  };

  // Handle save action with immediate UI feedback
  const handleSaveClick = async () => {
    setSaved(!saved);

    try {
      const response = await fetch(`${DomainUrl}/api/saved/savepost`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          postId: postId,
          userId: currentUserId,
        }),
      });

      if (!response.ok) {
        // Rollback UI in case of an error
        setSaved(saved);
        console.error('Failed to save/unsave the post');
      }
    } catch (error) {
      // Rollback UI in case of an error
      setSaved(saved);
      console.error('Error saving/unsaving the post:', error);
    }
  };

  const handleShareProfile = () => {
    setIsShareModalOpen(true);
    setIsPostMenuVisible(false);
  };

  const profileUrl = `${window.location.origin}/post/${postId}`;


  const recordInteraction = async () => {
    try {
      const response = await fetch(`${DomainUrl}/api/posts/interaction`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: currentUserId,
          postId: postId,
        }),
      });

      if (!response.ok) {
        console.error('Failed to record interaction');
      }
    } catch (error) {
      console.error('Error recording interaction:', error);
    }
  };

  const handlePostClick = (e) => {
    e.preventDefault(); // Prevent the default Link behavior
    recordInteraction(); // Record the interaction
    navigate(`/post/${postId}`, { state: { postId } }); // Navigate to the post
  };
  return (
    <div className="post ">
      <div className='postElement '>
        <Link
          to={`/post/${postId}`}
          state={{ postId }}
          style={{ color: 'inherit', textDecoration: 'none' }}
          onClick={handlePostClick}
        >          
        <div className='wrap '>
            {/* Ensure default post image is displayed if no image is provided */}
            <img 
              src={postImages.length > 0 ? `https://heybruh.nyc3.cdn.digitaloceanspaces.com/uploads/posts/${postImages[0]}` : postDefaultImage} 
              alt={postTitle} 
              className='postImg ' 
            />
            <div className='profDetails '>
              {/* Ensure default profile picture is displayed if none is provided */}
              <img 
                src={userProfPic !== null ? `https://heybruh.nyc3.cdn.digitaloceanspaces.com/uploads/profile/${userProfPic}` : draftDefaultImage} 
                alt={userName} 
                className='profPic ' 
              />
              <p className='userName '>{userName}</p>
              <p className='time '>{moment(time).fromNow()}</p>
            </div>
          </div>

          <div className='textContent '>
          <h1 className='postTitle '>
          {(() => {
              try {
                return decodeURIComponent(truncatedTitle);
              } catch (error) {
                console.error('Error decoding URI:', error);
                return truncatedTitle; // fallback to original if decoding fails
              } finally {
                
                // Only return postTitle if it exceeds the maximum length
                if (postTitle.length > MAX_TITLE_LENGTH) {
                  return decodeURIComponent(postTitle);
                }
              }
            })()}

          </h1>   
          
          <p className='postContent ' dangerouslySetInnerHTML={{ __html: decodeURIComponent(truncatedContent) }} />

          </div>
        </Link>

        <div className='postFooter'>

          <div className='categoryNameFooter'>
          <Link to={`/categoryList/${category}`} state={{ category }} style={{ textDecoration: 'none', color: '#fff' }}>
            <span>
              {category}
            </span>
          </Link>
          </div>

          <div className='footerRight'>

            <div className='viewsDiv'>
                      <img src={Views} alt="views" className='viewsIcon' />
                      <p className='viewsCount'>{formatFollowerCount(views)}</p>
                    </div>

                    <img src={Share} alt='Share Icon' onClick={handleShareProfile} className='share'></img>
                    <img src={saveIcon} alt='Save Icon' onClick={handleSaveClick} className='save'></img>
                  </div>
          </div>
          
      </div>
      <ShareModal
                    isOpen={isShareModalOpen}
                    onClose={() => setIsShareModalOpen(false)}
                    url={profileUrl}
                    name={"Post"}
                    />
    </div>
  );
};

export default PostModel;

// Break point