import React from 'react';
import { Link } from 'react-router-dom';
import './DraftPost.scss';
import moment from 'moment';

const draftDefaultImage = '/DefaultImg/profileDefault.png';;
const postDefaultImage = '/DefaultImg/draftDefault.jpg';

const DraftPost = ({ 
  postId,
  userName = "Anonymous",
  userProfPic = "defaultProfilePic.jpg",
  postImages = [],
  postTitle = "Untitled",
  postContent = "No content available.",
  time = "Just now",
}) => {

  

  const MAX_TITLE_LENGTH = 50;
  const MAX_CONTENT_LENGTH = 250;
  const from = 'DraftPost';

  const truncatedTitle = postTitle.length > MAX_TITLE_LENGTH
    ? `${postTitle.substring(0, MAX_TITLE_LENGTH)}...`
    : postTitle;

  const truncatedContent = postContent.length > MAX_CONTENT_LENGTH
    ? `${postContent.substring(0, MAX_CONTENT_LENGTH)}...`
    : postContent;


  return (
    <div className="postDP">
      <div className='postElementDP'>
        <Link to={`/edit-post/${from}/${postId}`} state={{ draft_id: postId, from }} style={{ color: 'inherit', textDecoration: 'none' }}>
          <div className='wrapDP'>
              <img 
              src={postImages.length > 0 ? `https://heybruh.nyc3.cdn.digitaloceanspaces.com/uploads/posts/${postImages[0]}` : postDefaultImage} 
              alt={postTitle} 
              className='postImgDP' 
            />
            

            <div className='profDetailsDP'>
              <img src={userProfPic !== "defaultProfilePic.jpg" ? `https://heybruh.nyc3.cdn.digitaloceanspaces.com/uploads/profile/${userProfPic}` : draftDefaultImage} 
              alt={userName} className='profPicDP'/>
              <p className='userNameDP'>{userName}</p>
              <p className='timeDP'>{moment(time).fromNow()}</p>
            </div>
          </div>
          
          <div className='textContentCP'>
          <h2 className='postTitleCP'>
          {(() => {
              try {
                return decodeURIComponent(truncatedTitle);
              } catch (error) {
                console.error('Error decoding URI:', error);
                return truncatedTitle; // fallback to original if decoding fails
              } finally {
                
                // Only return postTitle if it exceeds the maximum length
                if (postTitle.length > MAX_TITLE_LENGTH) {
                  return decodeURIComponent(postTitle);
                }
              }
            })()}

          </h2>   
          
          <p className='postContentCP' dangerouslySetInnerHTML={{ __html: decodeURIComponent(truncatedContent) }} />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default DraftPost;