import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef, useCallback  } from 'react';
import '../createPost/CreatePost.scss';
import 'react-quill/dist/quill.snow.css'; 
import ReactQuill from 'react-quill';
import axios from 'axios';
import { useUser } from '../../App';
import { DomainUrl } from '../../config';

const EditPost = () => {
  const location = useLocation();
  const params = useParams();
  const { postId: paramPostId, from: paramFrom } = params;
  const { postId: statePostId, from: stateFrom } = location.state || {};
  const postId = statePostId || paramPostId || params.id;
  const from = stateFrom || paramFrom || params.from;
  const [images, setImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [content, setContent] = useState('');
  const [category, setCategory] = useState('');
  const [title, setTitle] = useState('');
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const currentUserId = useUser();
  const navigate = useNavigate();
  const [categoryError, setCategoryError] = useState('');

  const [filteredCategories, setFilteredCategories] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const [charCount, setCharCount] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const quillRef = useRef(null);
  const [success, setSuccess] = useState(false);

  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && e.target.tagName !== 'TEXTAREA') {
      e.preventDefault();
    }
  }

  const containsEmoji = (text) => {
    const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F1E0}-\u{1F1FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u;
    return emojiRegex.test(text);
  };

  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode !== null ? JSON.parse(savedMode) : false;
  });
  // UseEffects

// Retrieving post from db...
useEffect(() => {
  const fetchPost = async () => {
    if (!postId) {
      setError('Missing postId parameter');
      setLoading(false);
      return;
    }

    try {
      let response;
      if (from === 'DraftPost') {
        response = await axios.get(`${DomainUrl}/api/drafts/retrieveDraft/${postId}`);
      } else {
        response = await axios.get(`${DomainUrl}/api/posts/retrievePost/${postId}`);
      }
      
      const postData = response.data;
      setPost(postData);
      setTitle(decodeURIComponent(postData.title));
      setCategory(decodeURIComponent(postData.category));
      setContent(decodeURIComponent(postData.content));
      
      // Handle images based on whether it's a draft or published post
      const imageUrls = from === 'DraftPost' 
        ? postData.images.map(image => ({ url: `https://heybruh.nyc3.cdn.digitaloceanspaces.com/uploads/posts/${image}`, isExisting: true, name: image }))
        : postData.images.map(image => ({ url: `https://heybruh.nyc3.cdn.digitaloceanspaces.com/uploads/posts/${image}`, isExisting: true, name: image }));
      
      setImages(imageUrls);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching post:', err);
      setError('Failed to fetch post');
      setLoading(false);
    }
  };

  fetchPost();
}, [postId, from]);

  useEffect(() => {
    const savedMode = localStorage.getItem('darkMode') === 'true';
    setIsDarkMode(savedMode);
  }, []);

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        delta.ops = delta.ops.filter(op => !op.insert || typeof op.insert === 'string');
        return delta;
      });
      quill.root.addEventListener('drop', (event) => {
        event.preventDefault();
      });
    }
  }, []);

  useEffect(() => {
    if (category) {
      const filtered = categories.filter((cat) =>
        cat.toLowerCase().includes(category.toLowerCase())
      );
      setFilteredCategories(filtered);
    } else {
      setFilteredCategories(categories);
    }
  }, [category, categories]);

  useEffect(() => {
    return () => {
      images.forEach(image => {
        if (image.url && image.url.startsWith('blob:')) {
          URL.revokeObjectURL(image.url);
        }
      });
    };
  }, [images]);


  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(isDarkMode));
  }, [isDarkMode]);


  // Methods

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const newImagePreviews = files.map(file => ({
      file,
      url: URL.createObjectURL(file),
      isExisting: false,
      name: file.name
    }));
    setNewImages(prevImages => [...prevImages, ...newImagePreviews].slice(0, 5 - images.length));
  };

  const handleRemoveImage = (index, isNewImage) => {
    if (isNewImage) {
      setNewImages(prevImages => prevImages.filter((_, i) => i !== index));
    } else {
      setImages(prevImages => prevImages.filter((_, i) => i !== index));
    }
  };

  const toggleDarkMode = () => {
    setIsDarkMode(prevMode => !prevMode);
  };

  const handleCategoryChange = async (e) => {
    const value = e.target.value;
    
    if (containsEmoji(value)) {
      setCategoryError('Category cannot contain emoji');
    } else {
      setCategoryError('');
    }

    setCategory(value);
    setShowDropdown(true);

    if (value.length > 0 && !containsEmoji(value)) {
      setIsLoading(true);
      try {
        const response = await fetch(`${DomainUrl}/api/posts/categorysearch?query=${encodeURIComponent(value)}`);
        if (response.ok) {
          const data = await response.json();
          setCategories(data);
        } else {
          console.error('Failed to fetch categories');
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setCategories([]);
    }
  };

  const handleCategorySelect = (selectedCategory) => {
    setCategory(selectedCategory);
    setShowDropdown(false);
  };

  const handleToggleDropdown = () => {
    setShowDropdown((prevShow) => !prevShow);
  };

  const handleContentChange = useCallback((value) => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const text = quill.getText();
      const newCharCount = text.length - 1;

      if (newCharCount <= 15000) {
        setContent(value);
        setCharCount(newCharCount);
      } else {
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 3000);
      }
    }
  }, []);
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link'],
      [{ 'align': [] }],
      ['clean']
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'link',
    'align'
  ];


  const handleUpdatePost = async (e) => {
    e.preventDefault();
    
    if (containsEmoji(category)) {
      setCategoryError('Category cannot contain emoji');
      return; // Prevent form submission
    }
    
    setLoading(true);

    const formData = new FormData();
    if (from === 'DraftPost') {
      formData.append('draft_id', postId);
    } else {
      formData.append('post_id', postId);
    }
    formData.append('title', encodeURIComponent(title));
    formData.append('category', encodeURIComponent(category));
    formData.append('content', encodeURIComponent(content));

    // Append existing images that weren't removed
    const remainingExistingImages = images.map(img => img.name);
    formData.append('existingImages', JSON.stringify(remainingExistingImages));

    // Append new images
    newImages.forEach((image) => {
      formData.append('newImages', image.file);
    });

    try {
      let res;
      if (from === 'DraftPost') {
        res = await axios.put(`${DomainUrl}/api/drafts/updatedraft`, formData);
      } else {
        res = await axios.put(`${DomainUrl}/api/posts/updatepost`, formData);
      }
      setLoading(false);
      setSuccess(true);
      alert(from === 'DraftPost' ? 'Draft updated successfully!' : 'Post updated successfully!');
      navigate(-1);
    } catch (err) {
      setLoading(false);
      console.error('Error updating post:', err);
      alert('Failed to update. Please try again.');
    }
  };



  const handlePublish = async (e) => {
    e.preventDefault();
    if (containsEmoji(category)) {
      setCategoryError('Category cannot contain emoji');
      return; // Prevent form submission
    }
    
    if (loading) return; // Prevent multiple submissions
    setLoading(true);
  
    const formData = new FormData();
    formData.append('user_id', currentUserId);
    formData.append('title', encodeURIComponent(title));
    formData.append('category', encodeURIComponent(category));
    formData.append('content', encodeURIComponent(content));

      // Combine all images in the order they appear
      const allImages = [...images, ...newImages];

      // Process images sequentially to maintain order
      for (let i = 0; i < allImages.length; i++) {
        const image = allImages[i];
        try {
          let file;
          if (image.isExisting) {
            // For existing images, fetch the file from the server
            const response = await fetch(image.url);
            const blob = await response.blob();
            file = new File([blob], image.name, { type: blob.type });
          } else {
            // For new images, use the file directly
            file = image.file;
          }
          formData.append('images', file);
          
          // Append the index to maintain order
          formData.append('imageOrder', i);
        } catch (error) {
          console.error(`Error processing image ${image.name}:`, error);
        }
      }
  
    try {
      const response = await fetch(`${DomainUrl}/api/posts/uploadpost`,
        {
          method: 'POST',
          body: formData,
        }
      );
  
      if (response.ok) {
        alert('Post uploaded successfully!');
        // Reset form
        setTitle('');
        setCategory('');
        setContent('');
        setImages([]);
        setCharCount(0);


        await handleDeleteDraftAfterPublish();

        navigate('/draft-list'); // Navigate to drafts page or wherever appropriate


      } else {
        alert('Failed to submit post.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error submitting post.');
    } finally {
      setLoading(false);
    }
  };

  const handleDeletePost = async () => {
    if (window.confirm('Are you sure you want to delete this draft?')) {
      try {
        await axios.delete(`${DomainUrl}/api/drafts/deletedraft/${postId}`);
        alert('Draft deleted successfully!');
        navigate('/draft-list'); // Navigate to drafts page or wherever appropriate
      } catch (err) {
        console.error('Error deleting draft:', err);
        alert('Failed to delete draft. Please try again.');
      }
    }
  };


  const handleDeleteDraftAfterPublish = async () => {
      try {
        await axios.delete(`${DomainUrl}/api/drafts/deletedraft/${postId}`);
      } catch (err) {
        console.error('Error deleting draft:', err);
        alert('Failed to delete draft. Please try again.');
      }

  };




  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!post) return <div>Post not found</div>;
  if (currentUserId !== post.user_id) {
    navigate(-1);
    return null;
  }



  return (
    <div className={`create-post ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
      <button className="toggle-mode" onClick={toggleDarkMode}>
        {isDarkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
      </button>

      <h1 className="post-heading">
        {from === 'DraftPost' ? 'Edit Draft' : 'Edit Post'}
      </h1>

      <form onSubmit={handleUpdatePost} onKeyDown={handleKeyDown}>
        <input
          type="text"
          placeholder="Enter Post Title"
          value={decodeURIComponent(title)}
          onChange={(e) => setTitle(e.target.value)}
          className={`post-title ${isDarkMode ? 'dark-mode' : ''}`}
        />

    <div className="category-section" ref={dropdownRef}>
        <div className="category-input-container">
          <input
            type="text"
            placeholder="Enter or Select Category"
            value={decodeURIComponent(category)}
            onChange={handleCategoryChange}
            onFocus={() => setShowDropdown(true)}
            className={`category-input ${isDarkMode ? 'dark-mode' : ''} ${categoryError ? 'error' : ''}`}
            required
          />
          
        </div>
        {categoryError && (
          <div className="error-message">{categoryError}</div>
        )}
        {showDropdown && (
          <ul className="category-dropdown">
            {isLoading ? (
              <li>Loading...</li>
            ) : categories.length > 0 ? (
              categories.map((cat, index) => (
                <li key={index} onClick={() => handleCategorySelect(cat)}>
                  {decodeURIComponent(cat)}
                </li>
              ))
            ) : (
              <li>No matching categories</li>
            )}
          </ul>
        )}
      </div>

        <div className="image-upload">
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageUpload}
          disabled={images.length + newImages.length >= 5}
        />
        <div className="image-preview">
          {images.map((image, index) => (
            <div key={`existing-${index}`} className="image-container">
              <img src={image.url} alt={`Upload Preview ${index + 1}`} />
              <button type="button" onClick={() => handleRemoveImage(index, false)}>x</button>
            </div>
          ))}
          {newImages.map((image, index) => (
            <div key={`new-${index}`} className="image-container">
              <img src={image.url} alt={`New Upload Preview ${index + 1}`} />
              <button type="button" onClick={() => handleRemoveImage(index, true)}>x</button>
            </div>
          ))}
        </div>
      </div>

        <div className="quill-section">
          <ReactQuill
            ref={quillRef}
            value={decodeURIComponent(content)}
            onChange={handleContentChange}
            modules={modules}
            formats={formats}
            className={`post-content ${isDarkMode ? 'dark-mode' : ''}`}
            placeholder="Write your blog content here..."
          />
          <div className="char-count">
            {charCount}/15000 characters
          </div>
        </div>

        {showPopup && (
          <div className="popup">
            Character limit reached (15000 characters)
          </div>
        )}

        <div className="action-buttons">
        {from === 'DraftPost' ? (
          <>
            <button type="button" className="delete-post" onClick={handleDeletePost}>
              Delete Draft
            </button>
            <button type="submit" className="save-draft" >
              Save as Draft
            </button>
            <button type="button" className="publish" disabled={loading} onClick={(e) => handlePublish(e)}>
              {loading ? 'Publishing...' : 'Publish'}
            </button>
          </>
        ) : (
          <button type="submit" className="publish" disabled={loading}>
            {loading ? 'Updating...' : 'Update Post'}
          </button>
        )}
      </div>
      </form>
    </div>
  );
};

export default EditPost;


// From now on draft kingdom 
